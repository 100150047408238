import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

import type { JobsApiParams, LoadData } from '~/types/jobs';
import type { ApiPaginationData } from '~/types/api';

export const useJobsStore = defineStore('jobs', () => {
  const api = useApi();

  const loading = ref(false);
  const submitting = ref(false);
  const loadFormOpen = ref(false);
  const loadDetailOpen = ref(false);
  const loadData = ref<LoadData>({
    id: null,
    title: '',
    date: '',
    originCity: '',
    originState: '',
    originCountry: '',
    destinationCity: '',
    destinationState: '',
    destinationCountry: '',
    distance: '',
    rate: '',
    rateType: 'mile',
    escortPositions: [],
    status: 'open',
    phoneNumber: '',
    quickPay: false,
    textOnly: false,
    createdAt: '',
    messageCount: 0,
    isRecent: false,
  });
  const apiParams = ref<JobsApiParams>({
    page: 1,
    pageSize: 10,
    originState: '',
    destinationState: '',
    quickPayOnly: false,
    textOnly: false,
    escortPosition: '',
  });
  const paginationData = ref<ApiPaginationData>({
    total: 0,
    hasPrevious: false,
    previousPage: null,
    hasNext: false,
    nextPage: null,
    pages: 0,
  });
  const loads = ref([]);
  const loadsLastCheckedAt = ref<Date | null>(null);

  const createLoad = async (payload: any) => {
    const { trackIntercomEvent } = useIntercomStore();

    try {
      submitting.value = true;
      await api.post('/jobs', payload, { timeout: 3000 });
      await getLoadsByUser();
      loadFormOpen.value = false;
      clearLoadData();

      trackIntercomEvent({ event: 'Load Posted', data: payload });
      // refresh loads after 5 seconds, this allows us to get the amount of people that have gotten load alerts
      // TODO: is this necessary? it seems like there's a better way to do this
      setTimeout(() => {
        getLoadsByUser();
      }, 5000);
    } finally {
      submitting.value = false;
    }
  };

  const updateLoad = async (payload: any) => {
    const { trackIntercomEvent } = useIntercomStore();

    try {
      submitting.value = true;
      await api.put(`/jobs/${payload.id}`, payload.data);
      await getLoadsByUser();
      loadFormOpen.value = false;
      clearLoadData();

      trackIntercomEvent({ event: 'Load Updated', data: payload });
    } finally {
      submitting.value = false;
    }
  };

  const deleteLoad = async ({ id }: { id: number}) => {
    const { trackIntercomEvent } = useIntercomStore();

    try {
      submitting.value = true;
      await api.delete(`/jobs/${id}`);
      await getLoadsByUser();
      loadFormOpen.value = false;
      clearLoadData();

      trackIntercomEvent({ event: 'Load Deleted', data: { id } });
    } finally {
      submitting.value = false;
    }
  };

  const getLoadsByUser = async () => {
    const { settings } = storeToRefs(useSettingsStore());

    try {
      loading.value = true;

      apiParams.value.originState = apiParams.value.originState || '';
      apiParams.value.destinationState = apiParams.value.destinationState || '';
      apiParams.value.escortPosition = apiParams.value.escortPosition || '';

      const resp = await api.get('/jobs',
        {
          params: {
            ...apiParams.value,
            onlyShowOpenLoads: settings.value.onlyShowOpenLoads,
          },
        },
      );

      if (resp && resp.data) {
        loads.value = resp.data.items;
        paginationData.value = {
          total: resp.data.total,
          hasPrevious: resp.data.hasPrevious,
          previousPage: resp.data.previousPage,
          hasNext: resp.data.hasNext,
          nextPage: resp.data.nextPage,
          pages: resp.data.pages,
        };
        loadsLastCheckedAt.value = new Date(Date.now());
      }
    } finally {
      loading.value = false;
    }
  };

  const getAllLoads = async () => {
    const { settings } = storeToRefs(useSettingsStore());

    try {
      loading.value = true;

      apiParams.value.originState = apiParams.value.originState || '';
      apiParams.value.destinationState = apiParams.value.destinationState || '';
      apiParams.value.escortPosition = apiParams.value.escortPosition || '';

      const resp = await api.get(
        '/jobs/all',
        {
          params: {
            ...apiParams.value,
            onlyShowOpenLoads: settings.value.onlyShowOpenLoads,
          },
        },
      );

      if (resp && resp.data) {
        loads.value = resp.data.items;
        paginationData.value = {
          total: resp.data.total,
          hasPrevious: resp.data.hasPrevious,
          previousPage: resp.data.previousPage,
          hasNext: resp.data.hasNext,
          nextPage: resp.data.nextPage,
          pages: resp.data.pages,
        };
        loadsLastCheckedAt.value = new Date(Date.now());
      }
    } finally {
      loading.value = false;
    }
  };

  const clearLoadData = () => {
    loadData.value = {
      id: null,
      title: '',
      date: '',
      originCity: '',
      originState: '',
      originCountry: '',
      destinationCity: '',
      destinationState: '',
      destinationCountry: '',
      distance: '',
      rate: '',
      rateType: 'mile',
      escortPositions: [],
      status: 'open',
      phoneNumber: '',
      quickPay: false,
      textOnly: false,
      createdAt: '',
      messageCount: 0,
      isRecent: false,
    };
  };

  const clearApiParams = () => {
    apiParams.value = {
      page: 1,
      pageSize: 10,
      originState: '',
      destinationState: '',
      quickPayOnly: false,
      textOnly: false,
      escortPosition: '',
    };
  };

  const clearPaginationData = () => {
    paginationData.value = {
      total: 0,
      hasPrevious: false,
      previousPage: null,
      hasNext: false,
      nextPage: null,
      pages: 0,
    };
  };

  const reset = () => {
    loading.value = false;
    submitting.value = false;
    loadFormOpen.value = false;
    loadDetailOpen.value = false;
    clearLoadData();
    clearApiParams();
    clearPaginationData();
    loads.value = [];
    loadsLastCheckedAt.value = null;
  };

  return {
    loading,
    submitting,
    loadFormOpen,
    loadDetailOpen: loadDetailOpen as unknown as boolean,
    loadData: loadData as unknown as LoadData,
    apiParams: apiParams as unknown as JobsApiParams,
    paginationData: paginationData as unknown as ApiPaginationData,
    loads,
    loadsLastCheckedAt,
    createLoad,
    updateLoad,
    deleteLoad,
    getLoadsByUser,
    getAllLoads,
    clearLoadData,
    clearApiParams,
    clearPaginationData,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useJobsStore, import.meta.hot));
}
