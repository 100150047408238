export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, user-scalable=no"},{"hid":"description","name":"description","content":"Pilot Car Loads lets pilot car drivers access an oversize load board and create personalized load alerts. Find pilot car jobs quickly and easily. Pilot Car Companies can post loads for free."},{"hid":"msapplication-TileColor","name":"msapplication-TileColor","content":"#ffffff"},{"hid":"theme-color","name":"theme-color","content":"#ffffff"},{"hid":"og:image","name":"og:image","content":"https://pilotcarloads.com/og.jpg"}],"link":[{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#05505c"}],"style":[],"script":[{"src":"https://js.stripe.com/v3"}],"noscript":[],"titleTemplate":"%s | Pilot Car Loads","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false