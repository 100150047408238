export default defineNuxtPlugin(async () => {
  const authStore = useAuthStore();
  const { setAppView, setAndroidView, testToken } = authStore;
  const { isApp, loggedIn } = storeToRefs(authStore);

  const userStore = useUserStore();
  const { userData } = storeToRefs(userStore);

  const userAgent = useRequestHeader('user-agent');
  if (userAgent?.includes('PCL_')) {
    setAppView();
  }
  if (userAgent?.includes('PCL_ANDROID')) {
    setAndroidView();
  }

  if (isApp.value) {
    await testToken();
    if (loggedIn.value) {
      const userType = userData.value.type;
      const postLoginPage = userType === 'shipper' ? '/dashboard/post-load' : '/dashboard/loads';
      return navigateTo(postLoginPage);
    }

    const route = useRoute();

    if (!['/login', '/register', '/forgot-password', '/reset-password'].includes(route.fullPath)) {
      return navigateTo('/login');
    }
  }
});
