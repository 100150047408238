import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "active-plan": () => import("/opt/buildhome/repo/middleware/activePlan.ts"),
  app: () => import("/opt/buildhome/repo/middleware/app.ts"),
  driver: () => import("/opt/buildhome/repo/middleware/driver.ts"),
  "email-not-verified": () => import("/opt/buildhome/repo/middleware/emailNotVerified.ts"),
  "email-verified": () => import("/opt/buildhome/repo/middleware/emailVerified.ts"),
  "logged-in": () => import("/opt/buildhome/repo/middleware/loggedIn.ts"),
  marketing: () => import("/opt/buildhome/repo/middleware/marketing.ts"),
  shipper: () => import("/opt/buildhome/repo/middleware/shipper.ts"),
  superuser: () => import("/opt/buildhome/repo/middleware/superuser.ts")
}