import { default as PrismicPreviewSBglaf9VTYMeta } from "/opt/buildhome/repo/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as accessibilityXr1RkVknkKMeta } from "/opt/buildhome/repo/pages/accessibility.vue?macro=true";
import { default as appCIzZhzdW1jMeta } from "/opt/buildhome/repo/pages/app.vue?macro=true";
import { default as _91uid_937u18pI1ReiMeta } from "/opt/buildhome/repo/pages/blog/[uid].vue?macro=true";
import { default as _91uid_93qftmxZE7JgMeta } from "/opt/buildhome/repo/pages/blog/category/[uid].vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as _91num_93Raxyd27qfTMeta } from "/opt/buildhome/repo/pages/blog/page/[num].vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as cookiesZLbBnefQgdMeta } from "/opt/buildhome/repo/pages/cookies.vue?macro=true";
import { default as plansBh3qWLhw5BMeta } from "/opt/buildhome/repo/pages/dashboard/admin/plans.vue?macro=true";
import { default as users0o3OCenirZMeta } from "/opt/buildhome/repo/pages/dashboard/admin/users.vue?macro=true";
import { default as admin9VkxcRL1hOMeta } from "/opt/buildhome/repo/pages/dashboard/admin.vue?macro=true";
import { default as alertsHCnz3JhSuIMeta } from "/opt/buildhome/repo/pages/dashboard/alerts.vue?macro=true";
import { default as appKqt2S3JHUqMeta } from "/opt/buildhome/repo/pages/dashboard/app.vue?macro=true";
import { default as directoryhdb5UzHO1KMeta } from "/opt/buildhome/repo/pages/dashboard/directory.vue?macro=true";
import { default as loadsMFOnApv6FqMeta } from "/opt/buildhome/repo/pages/dashboard/loads.vue?macro=true";
import { default as post_45loadi6TB6WClCQMeta } from "/opt/buildhome/repo/pages/dashboard/post-load.vue?macro=true";
import { default as profileqOEG4tKnc1Meta } from "/opt/buildhome/repo/pages/dashboard/profile.vue?macro=true";
import { default as referral3QRiRzCrFaMeta } from "/opt/buildhome/repo/pages/dashboard/referral.vue?macro=true";
import { default as settingsHEo5lq1OdBMeta } from "/opt/buildhome/repo/pages/dashboard/settings.vue?macro=true";
import { default as upgradepmAcIpS7mqMeta } from "/opt/buildhome/repo/pages/dashboard/upgrade.vue?macro=true";
import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as load_45boardwIIR7YXOnOMeta } from "/opt/buildhome/repo/pages/load-board.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as pilot_45carsLev2zBzZziMeta } from "/opt/buildhome/repo/pages/pilot-cars.vue?macro=true";
import { default as plan_45errorLDLRCQm0CJMeta } from "/opt/buildhome/repo/pages/plan-error.vue?macro=true";
import { default as post_45a_45loadC4YSV3WVyhMeta } from "/opt/buildhome/repo/pages/post-a-load.vue?macro=true";
import { default as pricingQrzbg66BTdMeta } from "/opt/buildhome/repo/pages/pricing.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as registerynsVf0yvMzMeta } from "/opt/buildhome/repo/pages/register.vue?macro=true";
import { default as indexzIVGDwloyUMeta } from "/opt/buildhome/repo/pages/reset-password/[token]/index.vue?macro=true";
import { default as select_45planfikW8G99gaMeta } from "/opt/buildhome/repo/pages/select-plan.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as indexBktGoH7wosMeta } from "/opt/buildhome/repo/pages/upgrade-now/[token]/index.vue?macro=true";
import { default as completeXgfu2jfxzaMeta } from "/opt/buildhome/repo/pages/upgrade-now/complete.vue?macro=true";
import { default as indexkLTVf4CPzDMeta } from "/opt/buildhome/repo/pages/verify/email/[token]/index.vue?macro=true";
import { default as indexIG0AxEpeHzMeta } from "/opt/buildhome/repo/pages/verify/phone/[token]/index.vue?macro=true";
import { default as verifying_45emailoVIa20TIN8Meta } from "/opt/buildhome/repo/pages/verifying-email.vue?macro=true";
import { default as why_45choose_45usrFNUTyAtgZMeta } from "/opt/buildhome/repo/pages/why-choose-us.vue?macro=true";
export default [
  {
    name: "prismic-preview",
    path: "/preview",
    component: () => import("/opt/buildhome/repo/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue").then(m => m.default || m)
  },
  {
    name: "accessibility",
    path: "/accessibility",
    component: () => import("/opt/buildhome/repo/pages/accessibility.vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/opt/buildhome/repo/pages/app.vue").then(m => m.default || m)
  },
  {
    name: "blog-uid",
    path: "/blog/:uid()",
    component: () => import("/opt/buildhome/repo/pages/blog/[uid].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-uid",
    path: "/blog/category/:uid()",
    component: () => import("/opt/buildhome/repo/pages/blog/category/[uid].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-page-num",
    path: "/blog/page/:num()",
    component: () => import("/opt/buildhome/repo/pages/blog/page/[num].vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/opt/buildhome/repo/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-admin",
    path: "/dashboard/admin",
    meta: admin9VkxcRL1hOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-admin-plans",
    path: "plans",
    meta: plansBh3qWLhw5BMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/admin/plans.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-admin-users",
    path: "users",
    meta: users0o3OCenirZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/admin/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-alerts",
    path: "/dashboard/alerts",
    meta: alertsHCnz3JhSuIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/alerts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-app",
    path: "/dashboard/app",
    meta: appKqt2S3JHUqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/app.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-directory",
    path: "/dashboard/directory",
    meta: directoryhdb5UzHO1KMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/directory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-loads",
    path: "/dashboard/loads",
    meta: loadsMFOnApv6FqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/loads.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-post-load",
    path: "/dashboard/post-load",
    meta: post_45loadi6TB6WClCQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/post-load.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile",
    path: "/dashboard/profile",
    meta: profileqOEG4tKnc1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/profile.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-referral",
    path: "/dashboard/referral",
    meta: referral3QRiRzCrFaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/referral.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: settingsHEo5lq1OdBMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-upgrade",
    path: "/dashboard/upgrade",
    meta: upgradepmAcIpS7mqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/upgrade.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "load-board",
    path: "/load-board",
    component: () => import("/opt/buildhome/repo/pages/load-board.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "pilot-cars",
    path: "/pilot-cars",
    component: () => import("/opt/buildhome/repo/pages/pilot-cars.vue").then(m => m.default || m)
  },
  {
    name: "plan-error",
    path: "/plan-error",
    meta: plan_45errorLDLRCQm0CJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/plan-error.vue").then(m => m.default || m)
  },
  {
    name: "post-a-load",
    path: "/post-a-load",
    component: () => import("/opt/buildhome/repo/pages/post-a-load.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/opt/buildhome/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/opt/buildhome/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    component: () => import("/opt/buildhome/repo/pages/reset-password/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: "select-plan",
    path: "/select-plan",
    meta: select_45planfikW8G99gaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/select-plan.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/buildhome/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-now-token",
    path: "/upgrade-now/:token()",
    meta: indexBktGoH7wosMeta || {},
    component: () => import("/opt/buildhome/repo/pages/upgrade-now/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-now-complete",
    path: "/upgrade-now/complete",
    meta: completeXgfu2jfxzaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/upgrade-now/complete.vue").then(m => m.default || m)
  },
  {
    name: "verify-email-token",
    path: "/verify/email/:token()",
    meta: indexkLTVf4CPzDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/verify/email/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: "verify-phone-token",
    path: "/verify/phone/:token()",
    meta: indexIG0AxEpeHzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/verify/phone/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: "verifying-email",
    path: "/verifying-email",
    meta: verifying_45emailoVIa20TIN8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/verifying-email.vue").then(m => m.default || m)
  },
  {
    name: "why-choose-us",
    path: "/why-choose-us",
    component: () => import("/opt/buildhome/repo/pages/why-choose-us.vue").then(m => m.default || m)
  }
]