import axios, { AxiosError } from 'axios';
import { PROTECTED_ROUTE_PATTERNS } from '~/utils/constants';
import { useMainStore } from '~/stores/main';

import type { AxiosResponse } from 'axios';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      axios: () => {
        const { api: { baseUrl } } = useRuntimeConfig().public;

        const api = axios.create({
          baseURL: baseUrl,
          withCredentials: true,
        });

        const successResponseInterceptor = (response: AxiosResponse) => response;

        const errorResponseInterceptor = async (error: AxiosError) => {
          const sentry = useSentry();

          const code = error?.response?.status || 500;

          if ([401, 403].includes(code)) {
            // TODO: this may not work... need to test
            await api.post('/logout');

            const { clearProtectedStores } = useMainStore();

            clearProtectedStores();

            const route = useRoute();

            if (PROTECTED_ROUTE_PATTERNS.some(pattern => RegExp(pattern).test(route.path))) {
              await navigateTo({ path: '/login', query: { next: route.fullPath } });
              return;
            }
          } else {
            try {
              sentry?.withScope(scope => {
                scope.setExtras({ request: error?.request });
                sentry.captureException(error);
              });
            } catch (e) {
              console.error('sentry error', e);
            }
          }

          return Promise.reject(error);
        };

        api.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);

        return api;
      },
    },
  };
});
